<template>
  <div class="space-basic">
    <b-card
      img-src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Fcorinne-kutz-tMI2_-r5Nfo-unsplash.jpg?alt=media&token=6ccc9896-c943-4d07-ba94-53b296d78910"
      img-height="250px"
    >
      <h2 class="mb-2">
        {{ title }}
      </h2>
      <div class="text-right mb-3">
        <b-button
          variant="primary"
          class=""
          @click="apply"
        >
          <span>購入する</span>
        </b-button>
      </div>
      <div
        v-for="(item, index) in lessonList"
        :key="index"
        class="mb-1"
      >
        <div class="d-flex justify-content-between">
          <div class="">
            {{ item.lessonName }}
          </div>
          <div class="text-rights">
            <b-button
              variant="text-primary"
              size="sm"
              class="btn-wishlist"
              @click="showVideo(item.lessonURI)"
            >
              <feather-icon
                icon="PlayCircleIcon"
                class="mr-50"
              />
            </b-button>
          </div>
        </div>
      </div>
      <div class="text-right mt-3">
        <b-button
          variant="primary"
          class=""
        >
          <span>購入する</span>
        </b-button>
      </div>
    </b-card>
    <b-modal
      v-model="isShowModal"
      size="lg"
      ok-only
      ok-title="閉じる"
      centered
      @ok="refetchEvents"
    >
      <iframe
        :src="movieURI"
        width="640"
        height="400"
        frameborder="0"
        allow="autoplay; fullscreen"
        allowfullscreen
        class="videoSpace"
      />
    </b-modal>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BButton,
} from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import useLesson from '@/components/js/compositionAPI/lesson/useLesson'
import { useRouter } from '@core/utils/utils'
import store from '@/store'

export default {
  components: {
    BCard,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    dataDic: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const title = ref('')
    const movieURI = ref('')
    const lessonList = ref([])
    const isShowModal = ref(false)
    const { route } = useRouter()
    const {
      categoryA,
      getCategoryListsScoped,
    } = useLesson()

    onMounted(async () => {
      const category = categoryA.find(v => v.id === Number(route.value.query.id))
      title.value = category.labelName
      const ida = category.value
      const d = {
        categoryA: String(ida),
        categoryB: String(1),
      }
      lessonList.value = await getCategoryListsScoped(d)
    })

    const showVideo = item => {
      isShowModal.value = true
      const uri = item.split('/')
      movieURI.value = `https://player.vimeo.com/video/${uri[3]}?h=${uri[4]}`
    }
    const refetchEvents = () => {
      isShowModal.value = false
    }

    const apply = () => {
      store.dispatch('payment/pushItemToCartList', route.value.query.id)
    }

    return {
      isShowModal,
      title,
      movieURI,
      lessonList,
      showVideo,
      refetchEvents,
      apply,
    }
  },
}
</script>

<style scoped>
.videoSpace {
  width: 100%;
  margin: 0 auto 0 auto;
}
</style>
